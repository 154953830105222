<template>
  <div class="form">
    <Vcard v-if="active == 'vcard'"/>
    <Email v-if="active == 'email'"/>
    <Website v-if="active == 'website'"/>
    <Whatsapp v-if="active == 'whatsapp'"/>
    <Wifi v-if="active == 'wifi'"/>
  </div>
</template>

<script>
import Email from "@/components/forms/Email"
import Vcard from "@/components/forms/Vcard"
import Website from "@/components/forms/Website"
import Whatsapp from "@/components/forms/Whatsapp"
import Wifi from "@/components/forms/Wifi"

export default {
  components: {
    Email, Vcard, Website, Whatsapp, Wifi
  },
  name: 'Form',
  props: {
      active: {default: 'vcard'}
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
  watch: {
  },
  mounted() {
  }
}
</script>