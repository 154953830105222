<template>
  <form action="" @submit.prevent="submit()">
    <div class="preview">
      <h2>Preview</h2>
      <div class="preview_canvas">
        <div class="canvas">
          <canvas ref="canvas"></canvas>
        </div>
      </div>
    </div>

    <div class="fields">

      <div class="field" :class="{active: ssid != ''}">
        <input type="text" id="ssid" v-model="ssid">
        <label for="ssid">SSID</label>
      </div>

      <div class="field field-buttons" :class="{active: ssid != ''}">
        <label for="ssid">Segurança</label>
        <button :class="{active: encryption == 'WPA'}" @click.prevent="encryption = 'WPA'">WPA/WPA2</button>
        <button :class="{active: encryption == 'WEP'}" @click.prevent="encryption = 'WEP'">WEP</button>
        <button :class="{active: encryption == ''}" @click.prevent="encryption = ''">Nenhuma</button>
      </div>

      <div class="field" :class="{active: password != ''}">
        <input type="text" id="password" v-model="password">
        <label for="password">Password</label>
      </div>

    </div>

    <button type="submit">Download</button>
  </form>
</template>

<script>
var QRCode = require('qrcode')

export default {
  name: 'WebsiteForm',
  props: {
  },
  data() {
    return {
      ssid: '',
      password: '',
      encryption: 'WPA',
      dataURL: '',
    }
  },
  computed: {
    qrText() {
      return `WIFI:S:${this.ssid};T:${this.encryption};P:${this.password};;`
    },
  },
  methods: {
    generatePreview() {
      let opts = {
        errorCorrectionLevel: 'L',
        width: 400,
        margin: 1,
      }
      QRCode.toCanvas(this.$refs.canvas, this.qrText, opts, (error) => {
        if (error) console.error(error)
      })
    },
    generateDataURL() {
      let opts = {
        errorCorrectionLevel: 'L',
        type: 'image/png',
        margin: 1,
        width: 1500,
      }
      QRCode.toDataURL(this.qrText, opts, (err, url) => {
        if (err) throw err
        this.dataURL = url
      })
    },
    submit() {
      this.generateDataURL()
      let element = document.createElement('a');
      element.setAttribute('href', this.dataURL);
      element.setAttribute('download', 'qr.png');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
  watch: {
    qrText() {
      this.generatePreview()
    },
  },
  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
