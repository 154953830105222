<template>
  <form action="" @submit.prevent="submit()">
    <div class="preview">
      <h2>Preview</h2>
      <div class="preview_canvas">
        <div class="canvas">
          <canvas ref="canvas"></canvas>
        </div>
      </div>
    </div>

    <div class="fields">

      <div class="field" :class="{active: email != ''}">
        <input type="text" id="email" v-model="email">
        <label for="email">Email</label>
      </div>

      <div class="field" :class="{active: subject != ''}">
        <input type="text" id="subject" v-model="subject">
        <label for="subject">Assunto</label>
      </div>

      <div class="field" :class="{active: cc != ''}">
        <input type="text" id="cc" v-model="cc">
        <label for="cc">CC</label>
      </div>

      <div class="field" :class="{active: bcc != ''}">
        <input type="text" id="bcc" v-model="bcc">
        <label for="bcc">BCC</label>
      </div>

      <div class="field" :class="{active: body != ''}">
        <input type="text" id="body" v-model="body">
        <label for="body">Body</label>
      </div>

    </div>

    <button type="submit">Download</button>
  </form>
</template>

<script>
var QRCode = require('qrcode')

export default {
  name: 'EmailForm',
  props: {
  },
  data() {
    return {
      email: '',
      subject: '',
      cc: '',
      bcc: '',
      body: '',
      dataURL: '',
    }
  },
  computed: {
    params() {
      let values = []
      if( this.cc || this.bcc || this.subject || this.body ) {
        if( this.cc ) {
          values.push('cc')
        }
        if( this.bcc ) {
          values.push('bcc')
        }
        if( this.subject ) {
          values.push('subject')
        } 
        if( this.body ) {
          values.push('body')
        } 
      }
      return values
    },
    qrText() {
      if( this.email ) {
        let link = `mailto:${this.email}`
        if(this.params.length) {
          link += '?'
        }
        for( let i = 0; i < this.params.length; i++ ) {
          if( i != 0 ) link += '&'
          let text = this[this.params[i]]
          link += `${this.params[i]}=${text}`
        }
        return link
      }
      return ""
    },
  },
  methods: {
    generatePreview() {
      if( this.qrText ) {
        console.log(this.qrText)
        let opts = {
          errorCorrectionLevel: 'L',
          width: 400,
          margin: 1,
        }
        QRCode.toCanvas(this.$refs.canvas, this.qrText, opts, (error) => {
          if (error) console.error(error)
        })
      } else {
        this.$refs.canvas.getContext('2d').clearRect(0,0,this.$refs.canvas.width, this.$refs.canvas.height)
      }
    },
    generateDataURL() {
      let opts = {
        errorCorrectionLevel: 'L',
        type: 'image/png',
        margin: 1,
        width: 1500,
      }
      QRCode.toDataURL(this.qrText, opts, (err, url) => {
        if (err) throw err
        this.dataURL = url
      })
    },
    submit() {
      this.generateDataURL()
      let element = document.createElement('a');
      element.setAttribute('href', this.dataURL);
      element.setAttribute('download', 'qr.png');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
  watch: {
    qrText() {
      this.generatePreview()
    },
  },
  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
