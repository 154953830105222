<template>
  <div id="app">
    <Header v-model="active"/>
    <router-view class="main-view" :active="active"/>
  </div>
</template>

<script>
import Header from "@/components/Header"

export default {
  name: 'App',
  components: {
    Header,
  },
  data() { return {
    active: 'vcard'
  }}
}
</script>
