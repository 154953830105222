<template>
  <form action="" @submit.prevent="submit()">
    <div class="preview">
      <h2>Preview</h2>
      <div class="preview_canvas">
        <div class="canvas">
          <canvas ref="canvas"></canvas>
        </div>
      </div>
    </div>

    <div class="fields">
      <div class="field" :class="{active: name != ''}">
        <input type="text" id="name" v-model="name">
        <label for="name">Primeiro Nome</label>
      </div>

      <div class="field" :class="{active: lastName != ''}">
        <input type="text" id="lastName" v-model="lastName">
        <label for="lastName">Apelido</label>
      </div>

      <div class="field" :class="{active: title != ''}">
        <input type="text" id="title" v-model="title">
        <label for="title">Título</label>
      </div>

      <div class="field" :class="{active: email != ''}">
        <input type="text" id="email" v-model="email">
        <label for="email">Email</label>
      </div>

      <div class="field" :class="{active: phone != ''}">
        <input type="text" id="phone" v-model="phone">
        <label for="phone">Telefone</label>
      </div>

      <div class="field" :class="{active: company != ''}">
        <input type="text" id="company" v-model="company">
        <label for="company">Organização</label>
      </div>
    </div>

    <button type="submit">Download</button>
  </form>
</template>

<script>
var vCardsJS = require('vcards-js');
var QRCode = require('qrcode')

export default {
  name: 'ContactForm',
  props: {
  },
  data() {
    return {
      vCard: vCardsJS(),
      name: '',
      lastName: '',
      title: '',
      email: '',
      phone: '',
      company: '',
      dataURL: '',
    }
  },
  computed: {
    vCardText() {
      return this.vCard.getFormattedString()
    }
  },
  methods: {
    generatePreview() {
      let opts = {
        errorCorrectionLevel: 'L',
        width: 400,
        margin: 1,
      }
      QRCode.toCanvas(this.$refs.canvas, this.vCardText, opts, (error) => {
        if (error) console.error(error)
      })
    },
    generateDataURL() {
      let opts = {
        errorCorrectionLevel: 'L',
        type: 'image/png',
        margin: 1,
        width: 1500,
      }
      QRCode.toDataURL(this.vCardText, opts, (err, url) => {
        if (err) throw err
        this.dataURL = url
      })
    },
    submit() {
      this.generateDataURL()
      let element = document.createElement('a');
      element.setAttribute('href', this.dataURL);
      element.setAttribute('download', 'qr.png');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
  watch: {
    name() {
      this.vCard.firstName = this.name
    },
    lastName() {
      this.vCard.lastName = this.lastName
    },
    title() {
      this.vCard.title = this.title
    },
    email() {
      this.vCard.workEmail = this.email
    },
    phone() {
      this.vCard.workPhone = this.phone
    },
    company() {
      this.vCard.organization = this.company
    },
    vCardText() {
      this.generatePreview()
    }
  },
  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
