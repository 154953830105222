<template>
  <form action="" @submit.prevent="submit()">
    <div class="preview">
      <h2>Preview</h2>
      <div class="preview_canvas">
        <div class="canvas">
          <canvas ref="canvas"></canvas>
        </div>
      </div>
    </div>

    <div class="fields">
      <div class="field" :class="{active: phonenumber != ''}">
        <input type="text" id="phonenumber" v-model="phonenumber">
        <label for="phonenumber">Telefone</label>
      </div>
      <div class="field" :class="{active: message != ''}">
        <input type="text" id="message" v-model="message">
        <label for="message">Mensagem</label>
      </div>
    </div>

    <button type="submit">Download</button>
  </form>
</template>

<script>
var QRCode = require('qrcode')

export default {
  name: 'WhatsappForm',
  props: {
  },
  data() {
    return {
      message: '',
      phonenumber: '',
      dataURL: '',
    }
  },
  computed: {
    number() {
      if( this.phonenumber.startsWith('+351') ) {
        return this.phonenumber
      }
      return '+351' + this.phonenumber
    },
    qrData() {
      let url = `https://wa.me/${encodeURIComponent(this.number)}`
      if( this.message ) {
        url += `?text=${encodeURIComponent(this.message)}`
      }
      return url
    }
  },
  methods: {
    generatePreview() {
      let opts = {
        errorCorrectionLevel: 'L',
        width: 400,
        margin: 1,
      }
      QRCode.toCanvas(this.$refs.canvas, this.qrData, opts, (error) => {
        if (error) console.error(error)
      })
    },
    generateDataURL() {
      let opts = {
        errorCorrectionLevel: 'L',
        type: 'image/png',
        margin: 1,
        width: 1500,
      }
      QRCode.toDataURL(this.qrData, opts, (err, url) => {
        if (err) throw err
        this.dataURL = url
      })
    },
    submit() {
      this.generateDataURL()
      let element = document.createElement('a');
      element.setAttribute('href', this.dataURL);
      element.setAttribute('download', 'qr.png');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
  watch: {
    phonenumber() {
      this.generatePreview()
    },
    message() {
      this.generatePreview()
    },
  },
  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
