import Vue from 'vue'
import App from './App.vue'
import router from './router'

import 'normalize.css'
import './sass/index.scss'

Vue.config.productionTip = false

import VueGtag from "vue-gtag"
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTAG_ID },
}, router);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
