<template>
  <header>
    <svg class="logo" preserveAspectRatio="xMinYMid"><use :xlink:href="`${logo}#logo`"></use></svg>
    <div>
      <a @click.prevent="$emit('change', item.slug)" href="#" v-for="item in items" :key="item.slug">
        <svg>
          <use :xlink:href="`${icons}#${item.icon}`"></use>
        </svg>
        {{ item.text }}
      </a>
    </div>
  </header>
</template>

<script>
import icons from "@/assets/icons.svg"
import logo from '@/assets/logo.svg'

export default {
  name: 'Header',
  model: {
    prop: 'active',
    event: 'change',
  },
  props: {
    active: { default: 'vcard'}
  },
  data() {
    return {
      icons,
      logo,
      items: [
        {slug: 'vcard', text: 'Contacto', icon: 'contact-book'},
        {slug: 'email', text: 'Email', icon: 'website'},
        {slug: 'website', text: 'Website', icon: 'website'},
        {slug: 'whatsapp', text: 'Whatsapp', icon: 'whatsapp'},
        {slug: 'wifi', text: 'Wifi', icon: 'wifi'},
      ],
    }
  },
}
</script>
